<template>
    <Form
            @submit="getResetLink(data.inputFormData)"
            :validation-schema="schema"
            v-slot="{ errors }"
    >
        <div class="form-group mb-3">
            <label class="label">{{translation?.email}}</label>
            <Field
                    type="text"
                    class="form-control"
                    v-model="data.inputFormData.email"
                    :placeholder="translation?.email"
                    name="email"
            />
            <span class="text-danger">{{ errors.email }}</span>
        </div>
        <div class="form-group mb-2">
            <button
                    type="submit"
                    class="form-control btn btn-primary rounded submit px-3"
            >
                {{ translation?.get_reset_link }}
            </button>
        </div>
    </Form>
</template>

<script>
    import {Form, Field} from "vee-validate";
    import * as Yup from "yup";
    import HelperFunction from "@/common/helpers";
    import axios from "axios";
    import Cookies from "js-cookie";
    import {useStore} from "vuex";

    export default {
        name: "password-reset-form",
        props: ['translation'],
        components: {
            Form,
            Field,
        },
        setup() {
            const {data, showToast} = HelperFunction();
            const store = useStore();

            try {
                const schema = Yup.object().shape({
                    email: Yup.string().required(),
                });

                //OnCLick
                async function getResetLink(formData) {
                    try {
                        const getResetLinkEndpoint =
                            process.env.VUE_APP_BACKEND_URL +
                            vueConfig.Auth.GetResetLinkEndPoint;

                        const {email} = formData;

                        //API CALL
                        const response = await axios.post(getResetLinkEndpoint, formData);

                        //SET Cookie for Reset Email
                        response.status === 200 &&
                        Cookies.set("reset_email", email, {expires: 1});

                        showToast(response);
                    } catch (err) {
                        showToast(err);
                    }
                }

                return {
                    schema,
                    getResetLink,
                    data,
                };
            } catch (err) {
            }
        },
    };
</script>


   